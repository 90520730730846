<template>
  <section
    v-if="products"
    class="products-week"
    :class="{ 'is-none': !products.length }"
  >
    <div class="container">
      <div v-if="products.length" class="header-block">
        <h2 class="title">{{ $t(title) }}</h2>
        <router-link
          class="header-block__link"
          :to="{ name: 'catalog', params: { weekly: true } }"
          @click.native="gtmHandler"
          >{{ $t("showMore") }}</router-link
        >
      </div>
      <products-list v-if="products" :items="products" />
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getProductsByFilters } from "@/api/products";
import { gtmDataLayer } from "@/helpers";

export default {
  name: "WeeklyProductsList",
  props: {
    excludeProduct: {
      type: Number,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
  },
  components: {
    ProductsList: () => import("@/components/products/ProductsList.vue"),
  },
  data() {
    return {
      products: null,
      title: "productsWeek",
    };
  },
  computed: {
    catId() {
      return this.$i18n.locale === "kz" ? 15 : 1;
    },
    ...mapState("cityModule", ["selectedCity"]),
  },
  watch: {
    excludeProduct: {
      handler() {
        this.getWeeklyProducts(6);
      },
    },
    selectedCity: {
      deep: true,
      handler() {
        this.getWeeklyProducts();
      },
    },
  },
  created() {
    this.getWeeklyProducts();
  },
  mounted() {
    if (this.$router.app._route.fullPath === "/products") {
      this.title = "otherGoods";
    }
  },
  methods: {
    gtmHandler() {
      gtmDataLayer({
        event: "view_item_list",
        type: "ecommerce",
        items: this.products,
        category: "главная",
        listName: this.$t(this.title),
      });
    },
    getWeeklyProducts() {
      getProductsByFilters(
        {
          categories: [],
          discountTypes: [0],
          shopTypes: [],
        },
        { field: "id", sortType: "asc" },
        { page: 1, pageSize: this.pageSize },
        this.excludeProduct
      ).then((data) => {
        this.products = data.data;
      });
    },
  },
};
</script>
